import moment from 'moment';
import {
  Button,
  KIND,
} from 'baseui/button';
import { Block } from 'baseui/block';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStyletron } from 'styletron-react';
import {
  PaymentStatusType,
  RepaymentType,
  RepaymentTypesType,
} from 'types/RepaymentTypes';
import {
  setRepayment,
  setRepaymentSource,
} from 'store/slices/repayments';
import PriceFormatter from 'utils/priceFormatter';
import { unScalePrice } from 'utils/priceScale';
import AppChip from 'components/AppChip/AppChip';
import { useAppDispatch } from 'store/hooks';
import {
  AccessCheckType,
  AccessUnit,
} from 'components/Access/Access';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import hasAccess from 'utils/hasAccess';
import { StatefulTooltip } from 'baseui/tooltip';
import { tooltipOverrides } from 'screens/CommonHelpers';
import {
  listStyles,
  listItemStyles,
  customerListItemNameAndStatusesStyles,
  statusBadgeStyles,
  detailsStyles,
  listItemInfoStyles,
  warningStatusBadgeStyles,
} from './RepaymentListItemHelpers';

const RepaymentListItem = ({
  repayment,
  openRepaymentEditModal,
  openTPORepaymentAllocationModal,
}: {
  repayment: RepaymentType,
  openRepaymentEditModal: (repayment: RepaymentType) => void,
  openTPORepaymentAllocationModal: (repayment: RepaymentType) => void,
}) => {
  const { t } = useTranslation(['common', 'employees', 'organizations', 'repayments', 'payGroups', 'dateFormats']);
  const [css] = useStyletron();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleRepaymentClick = (id: number) => {
    dispatch(setRepayment(repayment));
    dispatch(setRepaymentSource('repayments'));
    history.push(`/repayments/${id}`);
  };

  return (
    <ul className={css(listStyles)}>
      <li className={css(listItemStyles)}>
        <div className={css(customerListItemNameAndStatusesStyles)}>
          {hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAClientManager])
            ? (
              <Block
                overrides={{
                  Block: {
                    style: {
                      display: 'inline-block',
                      width: 'calc(100% - 360px)',
                      '@media screen and (max-width: 768px)': {
                        width: 'calc(100% - 30px)',
                        alignSelf: 'start',
                      },
                      paddingLeft: 0,
                      paddingRight: 0,
                      textAlign: 'left',
                      margin: '16px',
                    },
                  },
                }}
              >

                <StatefulTooltip
                  accessibilityType="tooltip"
                  content={repayment?.name || repayment?.transactionId}
                  placement="topLeft"
                  overrides={tooltipOverrides(false)}
                >
                  <Block className="ellipsis">{repayment?.name || repayment?.transactionId}</Block>
                </StatefulTooltip>
              </Block>
            )
            : (
              <Button
                kind={KIND.tertiary}
                onClick={() => handleRepaymentClick(repayment?.id)}
                overrides={{
                  Root: {
                    style: {
                      display: 'inline-block',
                      width: 'calc(100% - 360px)',
                      '@media screen and (max-width: 768px)': {
                        width: 'calc(100% - 30px)',
                        alignSelf: 'start',
                      },
                      paddingLeft: 0,
                      paddingRight: 0,
                      textAlign: 'left',
                      marginLeft: '16px',
                    },
                  },
                }}
              >
                <StatefulTooltip
                  accessibilityType="tooltip"
                  content={repayment?.name || repayment?.transactionId}
                  placement="topLeft"
                  overrides={tooltipOverrides(false)}
                >
                  <Block className="ellipsis">{repayment?.name || repayment?.transactionId}</Block>
                </StatefulTooltip>
              </Button>
            )}
          <div className={css({ ...statusBadgeStyles, background: 'transparent' })}>
            {hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager]) && repayment.type === RepaymentTypesType.EWA
              ? (
                <>
                  <span className={css({
                    background: statusBadgeStyles.background,
                    padding: statusBadgeStyles.padding,
                    borderRadius: '4px',
                    marginRight: '15px',
                    ...(repayment.status === PaymentStatusType.FAILED ? warningStatusBadgeStyles : {}),
                  })}
                  >
                    {t(`repayments:paymentStatuses.${repayment?.status}`)}
                  </span>
                  <FontAwesomeIcon
                    icon={faPen}
                    className={css({ cursor: 'pointer', color: 'rgb(187, 0, 47)', marginRight: '15px' })}
                    onClick={() => openRepaymentEditModal(repayment)}
                  />
                </>
              )
              : (
                <span className={css({
                  background: statusBadgeStyles.background,
                  padding: statusBadgeStyles.padding,
                  borderRadius: '4px',
                  marginRight: '44px',
                  ...(repayment.status === PaymentStatusType.FAILED ? warningStatusBadgeStyles : {}),
                })}
                >
                  {t(`repayments:paymentStatuses.${repayment?.status}`)}
                </span>
              )}
          </div>
        </div>

        <div className={css(listItemInfoStyles)}>
          <div>
            <span className={css(detailsStyles)}>
              {t('repayments:type.label')}
              :
              <AppChip
                items={t(`repayments:repaymentType.${repayment?.type}`)}
              />
            </span>
            {repayment.type === RepaymentTypesType.EWA
            || (repayment.type === RepaymentTypesType.TPO
              && (repayment.status === PaymentStatusType.RECEIVED
                || repayment.status === PaymentStatusType.ALLOCATED))
              ? (
                <span className={css(detailsStyles)}>
                  {t('repayments:paymentReceived.label')}
                  :
                  <AppChip
                    items={moment(repayment?.dateReceived).format(t('dateFormats:standard'))}
                  />
                </span>
              )
              : null}
            <span className={css(detailsStyles)}>
              {t('repayments:amount.label')}
              :
              <AppChip
                items={PriceFormatter().format(unScalePrice(repayment?.amount?.value))}
              />
            </span>

            <span className={css(detailsStyles)}>
              {t('repayments:allocated.label')}
              :
              <AppChip
                items={PriceFormatter().format(unScalePrice(repayment?.allocatedAmount?.value))}
              />
            </span>

            <span className={css(detailsStyles)}>
              {t('repayments:balance.label')}
              :
              <AppChip
                items={PriceFormatter().format(unScalePrice(repayment?.balance?.value))}
              />
            </span>
          </div>
          {hasAccess(AccessCheckType.oneOf, [AccessUnit.EWAManager])
            && repayment.type === RepaymentTypesType.TPO
            && (repayment.status === PaymentStatusType.CREATED
              || repayment.status === PaymentStatusType.ACCEPTED
              || repayment.status === PaymentStatusType.COMPLETED)
            && (
              <Button
                kind={KIND.secondary}
                onClick={() => openTPORepaymentAllocationModal(repayment)}
                overrides={{
                  Root: {
                    style: {
                      marginRight: '50px',
                    },
                    props: {
                      id: `RepaymentListItem-manually-mark-${repayment.id}`,
                    },
                  },
                }}
              >
                {t('repayments:manuallyMarkTPO')}
              </Button>
            )}
        </div>
      </li>
    </ul>
  );
};

export default RepaymentListItem;
